import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/iconfont/iconfont.css'
import './assets/css/scss.scss'
// 判断当前打开的介质，非支付宝和微信 则提示
import '../static/pre'

import {
  Button, Tabbar, TabbarItem, Icon, NavBar, Toast, List, Form, Field, Checkbox,
  CheckboxGroup, Uploader, Divider, PullRefresh, DatetimePicker, Popup, Picker,
  Badge, Loading, Col, Row, Empty, ImagePreview, Tab, Tabs, RadioGroup,
  Radio, Overlay, Image as VanImage, Dialog, NoticeBar, Stepper, Pagination,
  Collapse, CollapseItem, Swipe, SwipeItem, Cell, CellGroup, PasswordInput,
  NumberKeyboard, CountDown

} from 'vant'

Vue.use(Button)
Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(Icon)
Vue.use(NavBar)
Vue.use(Toast)
Vue.use(List)
Vue.use(Form)
Vue.use(Field)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Uploader)
Vue.use(Divider)
Vue.use(PullRefresh)
Vue.use(DatetimePicker)
Vue.use(Popup)
Vue.use(Picker)
Vue.use(Badge)
Vue.use(Loading)
Vue.use(Col)
Vue.use(Row)
Vue.use(Empty)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(ImagePreview)
Vue.use(Overlay)
Vue.use(VanImage)
Vue.use(Dialog)
Vue.use(NoticeBar)
Vue.use(Stepper)
Vue.use(Pagination)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(PasswordInput)
Vue.use(NumberKeyboard)
Vue.use(CountDown)

Vue.config.productionTip = false
Vue.prototype.font_size = function () {
  var deviceWidth = document.documentElement.clientWidth || document.body.clientWidth
  if (deviceWidth <= 320) {
    deviceWidth = 320
  }

  if (deviceWidth <= 750) {
    deviceWidth = 750
  }

  if (deviceWidth <= 1366) {
    deviceWidth = 1366
  }

  if (deviceWidth >= 1920) {
    deviceWidth = 1920
  }
  var fontsize = (deviceWidth / 19.2) + 'px'
  document.getElementsByTagName('html')[0].style.fontSize = fontsize
}

// eslint-disable-next-line no-extend-native
String.prototype.startWith = function (str) {
  const reg = new RegExp('^' + str)
  return reg.test(this)
}

// eslint-disable-next-line no-extend-native
String.prototype.endWith = function (str) {
  const reg = new RegExp(str + '$')
  return reg.test(this)
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


import * as common from '../api/common'
import * as constant from '../api/constant'
import axios from 'axios'

export const actions = {
  async DEFAULTVHLISTACTIONS ({ commit, state }, force) {
    if (!state.myCarList.myCarList || force) {
      const para = {
        sid: state.sid.sid,
        openid: state.openID.openID
      }
      try {
        const { data } = await axios({
          url: common.cloudPayUrl + '/pub/pay.bill.park_plate.get_bind_park_vh',
          method: 'POST',
          data: para,
          header: {
            'content-type': 'application/json' // 默认值
          }
        })
        let myCarList = []
        if (data.code === common.resCode.OK) {
          myCarList = data.list
        }
        commit(constant.keyMyCarListVuex, myCarList)
      } catch (e) {
        console.log('ERRor', e)
      }
    }
  }
}

<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepalive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepalive"></router-view>

  </div>
</template>
<script>
export default {
  mounted () {
    fnResize()
    window.onresize = function () {
      fnResize()
    }

    function fnResize () {
      var deviceWidth =
        document.documentElement.clientWidth || window.innerWidth
      var fontsize = deviceWidth / 3.75 + 'px'
      document.getElementsByTagName('html')[0].style.fontSize = fontsize
    }
    // document.addEventListener('touchmove', function (event) {
    //   event = event.originalEvent || event
    //   if (event.scale !== 1) {
    //     event.preventDefault()
    //   }
    // }, { passive: false })
  }
}
</script>
<style lang="scss">
#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  font-family:PingFangSC-Regular, sans-serif;
  // font-family:BlinkMacSystemFont,'Helvetica Neue',Helvetica,Segoe UI,Arial,Roboto,'PingFang SC',miui,'Hiragino Sans GB','Microsoft Yahei',sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.input-container {
    touch-action: manipulation;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>

import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { mutations } from './mutations'
import { state } from './state'
import { getters } from './getters'
import { actions } from './actions'
import userInfo from './modules/userInfo'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState({
    storage: window.localStorage,
    reducer (val) {
      return {
        accessToken: val.accessToken,
        userInfo: val.userInfo,
        // userHistory: val.userHistory,
        userAccountPass: val.userAccountPass,
        accountRole: val.accountRole,
        code: val.code,
        historyCode: val.historyCode,
        sid: val.sid,
        channelPathID: val.channelPathID,
        channelPath: val.channelPath,
        queryLogHistory: val.queryLogHistory,
        wxZfbAuthInfo: val.wxZfbAuthInfo,
        phone: val.phone,
        adverInfo: val.adverInfo,
        payType: val.payType,
        saveRouteQueryData: val.saveRouteQueryData,
        plateNumber: val.plateNumber,
        appid: val.appid,
        myCarList: val.myCarList,
        openID: val.openID,
        applyBillInfo: val.applyBillInfo,
        escapeInfo: val.escapeInfo
      }
    }
  })],
  mutations: mutations,
  state: state,
  getters: getters,
  actions: actions,
  modules: {
    userInfo

  }
})

export const keyClearAllState = 'CLEARALLSTATE'
export const keyCodeVuex = 'CODE'
export const keyHistoryCodeVuex = 'HISTORYCODE'
export const keySidVuex = 'SID'
export const keyWxZfbAuthInfoVuex = 'WXZFBAUTHINFO'
export const keyPhoneVuex = 'PHONE'
export const keyAccountRoleVuex = 'ACCOUNTROLE'
export const keyChannelPathIDVuex = 'CHANNELPATHID'
export const keyChannelPathVuex = 'CHANNELPATH'
export const keyQueryLogHistoryVuex = 'QUERYLOGHISTORY'
export const keyAdverInfoVuex = 'ADVERINFO'
export const keyPayTypeVuex = 'PAYTYPE'
export const keySaveRouteQueryDataVuex = 'SAVEROUTEQUERYDATA'
export const keyPlateNumberVuex = 'PLATENUMBER'
export const keyAppIDVuex = 'APPID'
export const keyMyCarListVuex = 'MYCARLIST'
export const keyOpenIDVuex = 'OPENID'
export const keyApplyBillInfoVuex = 'APPLYBILLINFO'
export const keyEscapeVuex = 'ESCAPEINFO'

export const keyPayMonthCompleteInfoVuex = 'PAYMONTHCOMPLETEINfO'

export const keyBusinessInfoSession = 'businessInfo'
export const keyMonthTypeListSession = 'monthTypeList'
export const keyMonthUnitColumnsListSession = 'monthUnitColumnsList'
export const keySaveInfoSession = 'saveInfo'
export const keyPayTypeSession = 'payType'
export const keyRenewParaStorageSession = 'renewParaStorage'
export const keyRenewBillDetailTypeSession = 'billDetailType'

export const keyParkNameLocalStorage = 'park_name'
export const monthRentInfos = {
  cateObj: {
    cateEight: 8,
    cateNine: 9,
    cateTen: 10
  },
  businessType: 2,
  // 固定车充值延期=9; 固定车注册（当前不支持分组和储值车）=10; 分组充值=11; 储值充值=12
  chargeType: {
    vhRenew: 9,
    vhRegister: 10,
    vhGroupStore: 11,
    vhStore: 12,
    vhGroupPostpone: 13
  }
}

export const chargeTypeList = [
  {
    id: 0,
    type: 9,
    name: '月卡延期'
  },
  {
    id: 1,
    type: 10,
    name: '月卡注册'
  },
  {
    id: 2,
    type: 11,
    name: '分组充值'
  },
  {
    id: 3,
    type: 12,
    name: '储值充值'
  }
]

export const endDateFlag = 2999
export const startDateFlag = 1970

export const couponTypeArray = [
  {
    id: 1,
    type: 1,
    couponName: '金额减免券(券面)'
  },
  {
    id: 2,
    type: 2,
    couponName: '时长减免券(券面)'
  },
  {
    id: 3,
    type: 3,
    couponName: '折扣减免券(券面)'
  },
  {
    id: 4,
    type: 4,
    couponName: '全免券'
  },
  {
    id: 11,
    type: 11,
    couponName: '金额减免券'
  },
  {
    id: 12,
    type: 12,
    couponName: '时长减免券'
  },
  {
    id: 13,
    type: 13,
    couponName: '折扣减免券'
  }
]
export const couponTypeObj = {
  money: {
    id: 1,
    type: 1,
    couponName: '金额减免券(券面)'
  },
  time: {
    id: 2,
    type: 2,
    couponName: '时长减免券(券面)'
  },
  discount: {
    id: 3,
    type: 3,
    couponName: '折扣减免券(券面)'
  },
  allFree: {
    id: 4,
    type: 4,
    couponName: '全免券'
  },
  moneyActual: {
    id: 11,
    type: 11,
    couponName: '金额减免券'
  },
  timeActual: {
    id: 12,
    type: 12,
    couponName: '时长减免券'
  },
  disBreaksActual: {
    id: 13,
    type: 13,
    couponName: '折扣减免券'
  }
}

export const couponStatusList = [
  {
    id: 1,
    type: 1,
    typeName: '未使用'
  },
  {
    id: 2,
    type: 2,
    typeName: '已使用'
  },
  {
    id: 3,
    type: 3,
    typeName: '已回收'
  },
  {
    id: 4,
    type: 4,
    typeName: '已过期'
  }
]

export const policyInfo = `
    本停车个人信息收集与使用清单一览表
本停车将尽全力保护您的个人安全，并保障您在个人信息处理过程中的相关权益。为了向您提供小强停车的基本功能及附加功能，我们需要收集您在使用服务时通过您主动授权/提供等方式产生的个人信息。以下我们将逐一说明收集情况，方便您快速查阅
发布日期：2024年04月15日
生效日期：2024年04月15日

业务场景：授权登录
信息类型：手机号、微信昵称、头像
收集目的：用于注册创建账号并登录
处理方式：H5收集/用户输入

业务场景：自主停车
信息类型：车牌号、车型信息
收集目的：使用停车服务，

业务场景：支付功能
信息类型：姓名、微信支付账号
收集目的：为了使用在线支付功能
处理方式：H5收集

业务场景：客服和售后
信息类型：账号信息、订单信息
收集目的：用于客户咨询和售后服务及客诉处理
处理方式：H5收集

业务场景：发票
信息类型：对公发票：公司名称、公司税号、电子邮箱
对私发票：姓名、电子邮箱"收集目的：为客户开具发票以及方便客户收取发票
处理方式：用户输入

业务场景：商务合作
信息类型：手机号、登录密码、验证码、企业名称、联系人姓名、联系人手机号、合作内容描述、停车场详细地址、停车场车位数
收集目的：用于平台与合作伙伴进行联系。包含：①验证联系人身份真实性，②提供建立商务合作沟通渠道服务
处理方式：用户输入

本指引是本停车H5开发者“杭州育恩科技有限公司”（以下简称“开发者”）为处理你的个人信息而制定。
开发者处理的信息
· 为了根据用户提供的实时定位提供附近的停车场推荐的功能，开发者将在获取你的明示同意后，收集你的位置信息。

· 为了成功将平台上的图片保存至相册，开发者将在获取你的明示同意后，使用你的相册（仅写入）权限。

· 为了完成账号注册及账号登录时，开发者将在获取你的明示同意后，收集你的手机号。

· 为了完成账号注册、账号登录及联系平台线上客服时，开发者将在获取你的明示同意后，收集你的微信昵称、头像。

· 为了在车辆出停车场时，扫描道闸二维码进行停车费支付，开发者将在获取你的明示同意后，访问你的摄像头。

· 开发者收集你选中的照片或视频信息，用于将图片或文件信息上传用于客服沟通或订单评价。

· 开发者获取你选择的位置信息，用于搜索您附近停车场及提供导航功能。

· 开发者读取你的剪切板，用于复制订单号。

第三方插件信息/SDK信息
为实现特定功能，开发者可能会接入由第三方提供的插件/SDK。第三方插件/SDK的个人信息处理规则，请以其公示的官方说明为准。小强停车小程序接入的第三方插件信息/SDK信息如下：

SDK名称：统计分析/友盟+SDK服务

SDK提供方名称: 友盟同欣（北京）科技有限公司

SDK名称：高德地图SDK/为用户提供地图功能

SDK提供方名称: 高德软件有限公司

开发者收集你的位置信息，用于在地图中展示停车场位置，帮助用户在产品描述页面知晓停车场大致方位，帮助用户判断停车场与目的地距离

SDK名称：高德定位SDK/为用户提供定位功能

SDK提供方名称: 高德软件有限公司

开发者收集你的位置信息，用于在用户开启授权定位后，系统将给予用户主动推荐附近停车场

SDK名称：Udesk SDK

SDK提供方名称: 北京沃丰时代数据科技有限公司

开发者收集你的用户信息（微信昵称、头像），用于用户在平台内开打客服咨询对话框，咨询平台客服关于停车业务情况时，需要使用此SDK提供平台内客服和用户交流功能

你的权益
关于收集你的位置信息、使用你的相册（仅写入）权限、获取你选择的位置信息，你可以通“不允许”，撤回对开发者的授权。

关于收集你的手机号、收集你选中的照片或视频信息、读取你的剪切板，你可以通过“不允许”拒绝。法律法规另有规定的，开发者承诺将停止除存储和采取必要的安全保护措施之外的处理。

关于你的个人信息，你可以通过以下方式与开发者联系，行使查阅、复制、更正、删除等法定权利。

- 手机号: 13588786970

开发者对信息的存储
开发者承诺，除法律法规另有规定外，开发者对你的信息的保存期限应当为实现处理目的所必要的最短时间。

信息的使用规则
开发者将会在本指引所明示的用途内使用收集的信息

如开发者使用你的信息超出本指引目的或合理范围，开发者必须在变更使用目的或范围前，再次以通过小程序信息弹窗方式告知并征得你的明示同意。

信息对外提供
开发者承诺，不会主动共享或转让你的信息至任何第三方，如存在确需共享或转让时，开发者应当直接征得或确认第三方征得你的单独同意。

开发者承诺，不会对外公开披露你的信息，如必须公开披露时，开发者应当向你告知公开披露的目的、披露信息的类型及可能涉及的信息，并征得你的单独同意。

若你认为开发者未遵守上述约定，或有其他的投诉建议、或未成年人个人信息保护相关问题，可通过以下方式与开发者联系。
- 手机号: 13588786970

补充文档
了解更多个人信息处理规则可查看 补充文件

更新日期：2024年4月15日
生效日期：2024年4月15日
`

export const expireLen = [
  {
    label: '15分钟',
    value: 15
  },
  {
    label: '30分钟',
    value: 30
  },
  {
    label: '1小时',
    value: 60
  },
  {
    label: '24小时',
    value: 24 * 60
  },
  {
    label: '2天',
    value: 2 * 24 * 60
  },
  {
    label: '3天',
    value: 3 * 24 * 60
  },
  {
    label: '5天',
    value: 5 * 24 * 60
  },
  {
    label: '7天',
    value: 7 * 24 * 60
  },
  {
    label: '10天',
    value: 10 * 24 * 60
  },
  {
    label: '15天',
    value: 15 * 24 * 60
  },
  {
    label: '20天',
    value: 20 * 24 * 60
  },
  {
    label: '30天',
    value: 30 * 24 * 60
  },
  {
    label: '60天',
    value: 60 * 24 * 60
  },
  {
    label: '90天',
    value: 90 * 24 * 60
  },
  {
    label: '180天',
    value: 180 * 24 * 60
  },
  {
    label: '365天',
    value: 365 * 24 * 60
  },
  {
    label: '长期',
    value: -1
  }
]

export const dateRule = [
  '全时段',
  '工作日（周一至周五）08:00至17:00,',
  '22:00至次日09:00'
]

export const payTypeOptions = [
  { label: '微信', value: 1 },
  { label: '支付宝', value: 2 },
  { label: 'ETC', value: 5 },
  { label: '无感', value: 6 }
]
export const monthPayTypeList = [
  {
    id: 0,
    name: '白天包月',
    key: 1,
    cateID: 11,
    disabled: false
  },
  {
    id: 1,
    name: '工作日白天包月',
    key: 2,
    cateID: 9,
    disabled: false
  },
  {
    id: 2,
    name: '夜间包月',
    key: 3,
    cateID: 10,
    disabled: false
  },
  {
    id: 3,
    name: '月租(无限制包月)',
    key: 4,
    cateID: 8,
    disabled: false
  }
]
export const monthPayTypeMap = {
  day: {
    id: 0,
    name: '白天包月',
    key: 1,
    type: 4,
    cateID: 11
  },
  workingDay: {
    id: 1,
    name: '工作日白天包月',
    key: 2,
    type: 6,
    cateID: 9
  },
  night: {
    id: 2,
    name: '夜间包月',
    key: 3,
    type: 4,
    cateID: 10
  },
  month: {
    id: 3,
    name: '月租(无限制包月)',
    key: 4,
    cateID: 8
  }
}

export const targetIds = [8, 9, 10, 11]

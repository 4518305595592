const userInfo = {
  namespaced: true,
  state: {
    userInfo: {
      username: 'lg'
    }
  },
  mutations: {
    USERINFO (state, info) {
      if (info) {
        state.userInfo = info
      } else {
        state.userInfo = null
      }
    }
  },
  getters: {
    userInfo: state => state.userInfo
  }
}

export default userInfo

var ua = window.navigator.userAgent.toLowerCase()// 获取判断用的对象
if (ua.match(/MicroMessenger/i)) {

} else if (ua.match(/AlipayClient/i)) {
  var zfbScript = document.createElement('script')
  zfbScript.src = 'https://gw.alipayobjects.com/as/g/h5-lib/alipayjsapi/3.1.1/alipayjsapi.inc.min.js'
  zfbScript.setAttribute('defer', true)
  zfbScript.setAttribute('async', true)
  document.head.appendChild(zfbScript)
} else {
  window.onload = function () {
    document.body.innerHTML = '<P style="margin: 60px auto;font-size: 16px;text-align: center">请使用' +
      '<i style="font-size: 18px;color:blue;"> 微信 </i>或<i style="font-size: 18px;color:blue;"> 支付宝 </i>扫码</P>'
  }
}

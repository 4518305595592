export const mutations = {
  // 清除所有状态constant.keyFeeValForAccount
  CLEARALLSTATE (state, excludeKey) {
    Object.keys(state).forEach(key => {
      if (!excludeKey.includes(key)) {
        state[key][key] = null
      }
    })
  },

  ACCESSTOKEN (state, info) {
    if (info) {
      state.accessToken.accessToken = info
    } else {
      state.accessToken.accessToken = null
    }
  },
  USERACCOUNTPASS (state, info) {
    if (info) {
      state.userAccountPass.userAccountPass = info
    } else {
      state.userAccountPass.userAccountPass = null
    }
  },
  ACCOUNTROLE (state, info) {
    if (info) {
      state.accountRole.accountRole = info
    } else {
      state.accountRole.accountRole = null
    }
  },
  CODE (state, info) {
    if (info) {
      state.code.code = info
    } else {
      state.code.code = null
    }
  },
  HISTORYCODE (state, info) {
    if (info) {
      state.historyCode.historyCode = info
    } else {
      state.historyCode.historyCode = null
    }
  },
  SID (state, info) {
    console.log('aaaaaaa sid', state, info)
    if (info) {
      state.sid.sid = info
    } else {
      state.sid.sid = null
    }
  },
  CHANNELPATHID (state, info) {
    if (info) {
      state.channelPathID.channelPathID = info
    } else {
      state.channelPathID.channelPathID = null
    }
  },
  CHANNELPATH (state, info) {
    if (info) {
      state.channelPath.channelPath = info
    } else {
      state.channelPath.channelPath = null
    }
  },
  QUERYLOGHISTORY (state, info) {
    if (info) {
      state.queryLogHistory.queryLogHistory = info
    } else {
      state.queryLogHistory.queryLogHistory = null
    }
  },
  WXZFBAUTHINFO (state, info) {
    if (info) {
      state.wxZfbAuthInfo.wxZfbAuthInfo = info
    } else {
      state.wxZfbAuthInfo.wxZfbAuthInfo = null
    }
  },
  PHONE (state, info) {
    state.phone.phone = info || null
  },
  ADVERINFO (state, info) {
    state.adverInfo.adverInfo = info || null
  },
  PAYTYPE (state, info) {
    state.payType.payType = info || null
  },
  SAVEROUTEQUERYDATA (state, info) {
    state.saveRouteQueryData.saveRouteQueryData = info || null
  },
  PLATENUMBER (state, info) {
    state.plateNumber.plateNumber = info || null
  },
  APPID (state, info) {
    state.appid.appid = info || null
  },
  MYCARLIST (state, info) {
    state.myCarList.myCarList = info || null
  },
  OPENID (state, info) {
    state.openID.openID = info || null
  },
  APPLYBILLINFO (state, info) {
    state.applyBillInfo.applyBillInfo = info || null
  },
  ESCAPEINFO (state, info) {
    state.escapeInfo.escapeInfo = info || null
  },
  PAYMONTHCOMPLETEINfO (state, info) {
    state.payMonthCompleteInfo.payMonthCompleteInfo = info || null
  }

}
